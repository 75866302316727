<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="600"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Project</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
          cols="4"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_id"
            :is-add-all="false"
            :auto-select-first="false"
          />
        </v-col>
        <v-col
          cols="8"
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || `Project Name is required`, (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Project Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="8"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.client_id"
            :items="clients"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Client is required']"
            :loading="loadClients"
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Client<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="pb-0 pt-0"
          cols="4"
        >
          <v-text-field
            v-model="item.floor_count"
            type="number"
            min="0"
            :rules="[(v) => !!v || `Number Of Floors is required`]"
          >
            <template slot="label">
              Number Of Floors<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.client_address_id"
            :items="addresses"
            item-text="address"
            item-value="id"
            no-data-text="Select a Client first"
            :rules="[(v) => !!v || 'Client Address is required']"
            :loading="loadAddresses"
            autocomplete="preventAutocomplete"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item && item.type"
                small
                :color="item.type.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.type.name }}
              </v-chip>
              <span>
                {{ item.address }}
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item && item.type"
                small
                :color="item.type.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.type.name }}
              </v-chip>
              <span>
                {{ item.address }}
              </span>
            </template>
            <template slot="label">
              Job Address<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
      client_id: null,
      inventory_class_id: null,
      client_address_id: null,
      floor_count: null,
    },

    clients: [],
    addresses: [],

    loadClients: false,
    loadAddresses: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.client_id': function () {
      if (!this.item.client_id) return;
      this.getAddresses();
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.project.read(submitModel);
      if (!res || !res.data || !res.data[0]) return;
      const firstIndex = 0;
      this.item = res.data[firstIndex];
    },

    async getClients() {
      this.loadClients = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.item.inventory_class_id,
        },
      };
      const res = await this.$api.client.read(readModel);
      this.clients = res;
      this.loadClients = false;
    },

    async getAddresses() {
      this.loadAddresses = true;
      const readModel = {
        filters:
        {
          client_id: this.item.client_id,
        },
      };
      const res = await this.$api.address.read(readModel);
      this.addresses = res;
      if (res.length === 1) {
        this.item.client_address_id = res[0].id;
      }
      this.loadAddresses = false;
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.project.update(this.item);
      } else {
        res = await this.$api.project.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        // this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
      this.getClients();
    },
  },
};
</script>

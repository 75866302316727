/* eslint-disable no-param-reassign */
<template>
  <v-container
    fluid
  >
    <v-row
      v-if="getAdminModuleValue('Suppliers')"
      align="center"
    >
      <v-col
        class="pb-0 pt-0"
        cols="auto"
      >
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab>
            CLIENTS
          </v-tab>
          <v-tab>
            SUPPLIERS
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === 0">
      <v-col>
        <v-row align="center">
          <!-- <v-col
            cols="2"
            class="pb-0 pt-2"
          >
            <inventory-class-select-element
              v-model="inventoryClassId"
              :is-state="true"
            />
          </v-col> -->
          <v-col
            v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
            class="text-end pt-0"
          >
            <v-btn
              color="primary"
              outlined
              @click="openClientForm"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              new client
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card
              elevation="0"
              outlined
            >
              <v-data-table
                :headers="clientHeaders"
                :items="clients"
                :items-per-page="pagination.itemsPerPage"
                :footer-props="itemsTableFooterProps"
                :search="search"
                :height="windowHeight"
                fixed-header
                dense
                show-expand
                :expanded.sync="expandedItems"
                :loading="loading"
                :item-class="itemRowBackground"
                @update:options="updateOptions"
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      <v-icon
                        v-if="item.addresses && item.addresses.length > 0"
                        style="font-size: 22px !important;"
                        @click="toggleExpand(item)"
                      >
                        {{ expandedItems.includes(item) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                      </v-icon>
                    </td>
                    <td>
                      <text-highlight
                        :queries="search"
                        style="font-weight: bold;"
                      >
                        {{ item.name ? item.name : '-' }}
                      </text-highlight>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item.phone_number ? item.phone_number : '-' }}
                      </text-highlight>
                    </td>
                    <td>
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <span v-on="on">{{ item.company_address && item.company_address.address_simple ? item.company_address.address_simple : '' }}</span>
                        </template>

                        <text-highlight
                          :queries="search"
                        >
                          {{ item.company_address && item.company_address.address ? item.company_address.address : '' }}
                        </text-highlight>
                      </v-tooltip>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item.created_at ? item.created_at : '-' }}
                      </text-highlight>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item.created_by_name ? item.created_by_name : '-' }}
                      </text-highlight>
                    </td>
                    <td class="text-end">
                      <v-btn
                        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
                        class="ml-1 mt-1 mb-1"
                        color="purple"
                        outlined
                        small
                        @click="openAddressForm(item.id, item.name)"
                      >
                        <v-icon
                          x-small
                          class="mr-1"
                        >
                          fas fa-plus
                        </v-icon> Add Address
                      </v-btn>
                      <v-btn
                        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEditClientForm(item.id)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="deleteClient(item.id)"
                      >
                        DELETE
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template #expanded-item="{ headers, item }">
                  <td
                    v-if="item.addresses && item.addresses.length > 0"
                    :colspan="headers.length"
                    class="pl-0 pr-0"
                    style="padding-right: 0px !important; padding-left: 0px !important;"
                  >
                    <!-- :style="`background-color: ${item.index % 2 === 0 ? 'white' : '#E8F5E9'} !important;`" -->
                    <v-container
                      fluid
                      :style="`background-color: ${item.index % 2 === 0 ? isDarkMode ? '#1e1e1e' : 'white' : isDarkMode ? '#556b2f' : '#E8F5E9'} !important;`"
                    >
                      <v-row
                        v-for="address, index in item.addresses"
                        :key="address.id"
                        align="center"
                        justify="center"
                        :style="`border-bottom: ${index === item.addresses.length - 1 ? 'none' : '1px solid #c1c1c1'};`"
                      >
                        <v-col
                          class="pt-2 pb-2 pr-1 mr-3"
                          cols="1"
                        >
                          <v-chip
                            v-if="address.type"
                            class="mt-1 mb-1 mr-3"
                            small
                            text-color="white"
                            style="cursor: pointer;"
                            :color="address && address.type ? address.type.color : 'white'"
                          >
                            {{ address && address.type ? address.type.name : '' }}
                          </v-chip>
                        </v-col>
                        <v-col
                          class="pt-2 pb-2 pr-1 pl-0"
                          cols="2"
                        >
                          <span style="font-size: 14px;">Address: </span><span style="font-weight: bold; font-size: 14px;"><text-highlight :queries="search">{{ address.address_simple ? address.address_simple : '-' }}</text-highlight></span>
                        </v-col>
                        <v-col
                          class="pt-2 pb-2 pr-1 pl-0"
                          cols="2"
                        >
                          <span style="font-size: 14px;">City: </span><span style="font-weight: bold; font-size: 14px;"><text-highlight :queries="search">{{ address.city ? address.city : '-' }}</text-highlight></span>
                        </v-col>
                        <v-col
                          class="pt-2 pb-2 pr-1 pl-0"
                          cols="2"
                        >
                          <span style="font-size: 14px;">Prov/St: </span><span style="font-weight: bold; font-size: 14px;"><text-highlight :queries="search">{{ address.region_name ? address.region_name : '-' }}</text-highlight></span>
                        </v-col>
                        <v-col
                          class="pt-2 pb-2 pr-1 pl-0"
                          cols="1.5"
                        >
                          <span style="font-size: 14px;">Country: </span><span style="font-weight: bold; font-size: 14px;"><text-highlight :queries="search">{{ address.country_name ? address.country_name : '-' }}</text-highlight></span>
                        </v-col>
                        <v-col
                          class="pt-2 pb-2 pl-0"
                          cols="1.5"
                        >
                          <span style="font-size: 14px;">PC: </span><span style="font-weight: bold; font-size: 14px;"><text-highlight :queries="search">{{ address.postal_code ? address.postal_code : '-' }}</text-highlight></span>
                        </v-col>
                        <v-spacer />
                        <v-col
                          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
                          class="pt-1 pb-1 pr-0"
                          cols="auto"
                        >
                          <v-btn
                            color="primary"
                            outlined
                            small
                            @click="openEditAddressForm(item.id, item.name, address.id)"
                          >
                            EDIT
                          </v-btn>
                        </v-col>
                        <v-col
                          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleReadWrite)"
                          class="pt-1 pb-1 pl-1 pr-1"
                          cols="auto"
                        >
                          <v-btn
                            color="error"
                            outlined
                            small
                            @click="deleteAddress(address.id)"
                          >
                            DELETE
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === 1">
      <v-col>
        <supplier-view />
      </v-col>
    </v-row>
    <client-form
      ref="clientForm"
      @success="success"
    />
    <client-address-form
      ref="clientAddressForm"
      @success="successAddress"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ClientForm from '@/components/forms/clients/ClientForm';
import ClientAddressForm from '@/components/forms/clients/ClientAddressForm';
import SupplierView from '@/views/SupplierView';

export default {
  components: {
    ClientForm,
    ClientAddressForm,
    SupplierView,
  },

  data: () => ({
    clients: [],
    // inventoryClassId: null,
    expandedItems: [],
    itemsTableFooterProps: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    roles: [],
    userRoleReadWrite: 2,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    loading: false,
    selectedTab: 0,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    sort: {
      order_by_first: 'name',
      direction_sort: true,
    },
    windowHeight: 1000,
    topSpaceHeight: 240,
  }),
  computed: {
    clientHeaders() {
      const headers = [];
      // const invClassesBRName = 'Inventory Classes';
      // const invClassesBRValue = this.businessRulesGetBoolean(invClassesBRName);
      // if (invClassesBRValue != null && invClassesBRValue !== 0) { headers.push({ text: 'Class', value: 'inventory_class' }); }
      headers.push({
        text: '', value: 'data-table-expand', width: '2%',
      });
      headers.push({ text: 'Name', value: 'name', sortable: true  });
      headers.push({ text: 'Phone Number', value: 'phone_number', sortable: true  });
      headers.push({ text: 'Client (Bill to)', value: 'company_address', sortable: true  });
      headers.push({ text: 'Created On', value: 'created_at', sortable: true  });
      headers.push({ text: 'Created By', value: 'created_by_name', sortable: true });
      headers.push({
        text: 'Actions', value: 'actions', width: '25%', align: 'end',
      });
      return headers;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
      'getAdminModuleValue',
    ]),
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
      user: (state) => state.user,
      clientsState: (state) => state.clientsState,
    }),
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    // sort: {
    //   handler() {
    //     this.get();
    //   },
    //   deep: true,
    // },
    // inventoryClassId() {
    //   this.get();
    // },
  },
  created() {
    if (this.clientsState) {
      if (this.clientsState.pagination) { this.pagination = this.clientsState.pagination; }
    }
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    storeState(property, value) {
      const state = this.clientsState;
      state[property] = value;
      this.$store.commit('storeClientsState', state);
    },
    async init() {
      this.get();
    },
    async get() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      const readModel = {
        filters:
        {
          // inventory_class_id: this.inventoryClassId,
        },
        pagination: this.pagination,
        search: this.search,
        sort: this.sort,
      };
      const res = await this.$api.client.read(readModel);
      this.clients = res;
      this.clients.forEach((x, i) => {
        // eslint-disable-next-line no-param-reassign
        x.index = i;
      });
      this.expandedItems = [];
      this.loading = false;
    },
    async deleteClient(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;

      const res = await this.$api.client.delete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    itemRowBackground(item) {
      let itemClass = '';
      const rowEven = this.isDarkMode ? 'row-even-dark' : 'row-even';
      const rowOdd = this.isDarkMode ? 'row-odd-dark' : 'row-odd';
      itemClass += item.index % 2 === 0 ? rowEven : rowOdd;
      itemClass += ` ${item.status_id === 1 ? 'hide-icon' : ''}`;
      return itemClass;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    openClientForm() {
      this.$refs.clientForm.open();
    },

    openEditClientForm(id) {
      this.$refs.clientForm.open(id);
    },

    openAddressForm(clientId, clientName) {
      this.$refs.clientAddressForm.open(clientId, clientName);
    },
    openEditAddressForm(clientId, clientName, addressId) {
      this.$refs.clientAddressForm.open(clientId, clientName, addressId);
    },

    async deleteAddress(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;

      const res = await this.$api.address.delete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    success() {
      this.get();
    },
    successAddress() {
      this.get();
    },

    toggleExpand(item) {
      if (!item) return;
      const index = this.expandedItems.indexOf(item);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(item);
      }
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updateOptions(newOptions) {
      console.log('newOptions: ', newOptions);
      // if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
      //   this.pagination = pagination;
      //   this.storeState('pagination', pagination);
      // }
      this.pagination = newOptions;
      this.sort = {
        order_by_first: newOptions.sortBy[0],
        direction_sort: newOptions.sortDesc[0],
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    /* -webkit-box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%); */
    box-shadow: none !important;
}

::v-deep .row-odd{
  background-color: #E8F5E9 !important;
}

::v-deep .row-odd-dark{
  background-color: rgba(0, 77, 64, .1) !important;
}

::v-deep .row-even{
  background-color: white !important;
}

::v-deep .row-even-dark{
  background-color: #000 !important;
}

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-icon.v-icon.v-icon--link
{
  font-size: 18px;
}
</style>

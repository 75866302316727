<template>
  <v-container fluid>
    <!-- <v-row align="center">
      <v-col class="pb-0 pt-0">
        <v-tabs v-model="selectedTab">
          <v-tab> PROJECTS </v-tab>
          <v-tab> PROJECTS SCOPE </v-tab>
        </v-tabs>
      </v-col>
    </v-row> -->
    <v-row v-if="selectedTab === 0">
      <v-col>
        <v-row>
          <v-col
            cols="2"
            class="pb-0 pt-4"
          >
            <inventory-class-select-element
              v-model="inventoryClassId"
              :is-state="true"
            />
          </v-col>
          <v-spacer />
          <v-col class="text-end">
            <v-btn
              color="primary"
              outlined
              @click="open"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              New Project
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card
              elevation="0"
              outlined
              style="height: calc(100% + 24px)"
            >
              <v-data-table
                :headers="headers"
                :items="items"
                :footer-props="footer"
                :items-per-page="pagination.itemsPerPage"
                :search="search"
                :loading="loading"
                dense
                fixed-header
                :height="windowHeight"
                :server-items-length="total"
                :sort-by.sync="sort.order_by_first"
                :sort-desc.sync="sort.direction_sort"
                @pagination="updatePagination"
              >
                <template #[`item.class`]="{ item }">
                  <v-chip
                    v-if="item.class"
                    small
                    :color="item && item.class ? item.class.color : 'grey'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </template>
                <template #[`item.name`]="{ item }">
                  <text-highlight
                    :queries="search"
                    style="font-weight: bold"
                  >
                    {{ item.name }}
                  </text-highlight>
                </template>
                <template #[`item.client`]="{ item }">
                  <text-highlight :queries="search">
                    {{ item.client ? item.client.name : '' }}
                  </text-highlight>
                </template>
                <template #[`item.address`]="{ item }">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span v-on="on">{{
                        item.address_simple ? item.address_simple : ''
                      }}</span>
                    </template>

                    <text-highlight :queries="search">
                      {{ item.address_full ? item.address_full : '' }}
                    </text-highlight>
                  </v-tooltip>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openEdit(item.id)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1 mr-1"
                    color="error"
                    outlined
                    small
                    @click="softDelete(item.id)"
                  >
                    DELETE
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <project-form
      ref="projectForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import ProjectForm from '@/components/forms/projects/ProjectForm';
import { mapState } from 'vuex';

export default {
  components: {
    ProjectForm,
  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Class', value: 'class', width: '3%' },
      { text: 'Project Name', value: 'name', width: '15%' },
      { text: 'Client', value: 'client', width: '22%' },
      { text: 'Job Address', value: 'address', width: '15%' },
      { text: 'Number of Floors', value: 'floor_count', width: '8%'},
      { text: 'Created At', value: 'created_at_short', width: '10%' },
      { text: 'Created By', value: 'user_full_name', width: '7%' },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        width: '20%',
      },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    rowsPerPageItems: [10, 20, 50, 100, 500, 1000],
    loading: false,
    topSpaceHeight: 235,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
    totalRecords: 0,
    selectedTab: 0,
    table: 'projectView',
    inventoryClassId: null,
    sort: {
      order_by_first: 'created_at',
      direction_sort: true,
    },
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      projectsState: (state) => state.projectsState,
    }),
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    inventoryClassId() {
      this.get();
    },
    sort: {
      handler() {
        this.get();
      },
      deep: true,
    },
    // 'sort.order_by_first': 'get',
    // 'sort.direction_sort': 'get',
  },
  created() {
    if (this.projectsState) {
      if (this.projectsState.pagination) {
        this.pagination = this.projectsState.pagination;
      }
    }
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    storeState(property, value) {
      const state = this.projectsState;
      state[property] = value;
      this.$store.commit('storeProjectsState', state);
    },

    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
        pagination: this.pagination,
        search: this.search,
        sort: this.sort,
      };
      const res = await this.$api.project.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.$bus.$emit('showError');
        this.items = [];
        this.total = 0;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.project.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.projectForm.open();
    },

    openEdit(id) {
      this.$refs.projectForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (
        pagination.page !== this.pagination.page
        || pagination.itemsPerPage !== this.pagination.itemsPerPage
      ) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
        this.itemsPerPage = this.pagination.itemsPerPage;
      }
    },
  },
};
</script>
